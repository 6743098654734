<script>
import Vue from 'vue'
import { BootstrapVue } from 'bootstrap-vue'
import generalTable from '@/components/generalTable.vue'

Vue.use(BootstrapVue)
export default {
  components: {
    generalTable,
  },
  data() {
    return {
      parse_header: [],
      parse_csv: [],
      sortOrders: {},
      alertMsg: [],
      prepareForImport: false,
      paramsGrid: {
        selectOptions: {
          allowSelect: true,
          selectMode: 'single',
        },
        fields: [
          // { key: 'row', label: '', sortable: false },
          { key: 'nombre', label: 'Nombre', sortable: true },
          { key: 'apellido', label: 'Apellido', sortable: true },
          { key: 'role', label: 'Rol', sortable: true },
          {
            key: 'actions',
            label: 'Acciones',
            tdClass: 'text-center',
            thClass: 'text-center',
            sortable: false,
          },
        ],
        // filters: {
        //   prod_nombre: '',
        //   prod_stock_actual: '',
        // },
        urlBack: '/api/users',
        edit: {
          available: true,
          redirect: true,
          ruta: '/usuario/editar',
        },
        delete: {
          available: true,
          ruta: '/api/users',
        },
        options: {
          responsive: true,
          primaryKey: 'id',
        },
        pagination: true,
      },
    }
  },
  methods: {
    agregar() {
      this.$router.push({ name: 'usuario-agregar' })
    },
  },
}
</script>

<template>
  <div>
    <b-card>
      <b-row>
        <b-col md="6" />
        <b-col
          md="6"
          class="text-right"
        >
          <b-button
            variant="primary"
            @click="agregar"
          >
            Agregar Elemento
          </b-button>
        </b-col>
      </b-row>
      <generalTable :params-grid="paramsGrid" />
    </b-card>
  </div>
</template>

<style lang="scss">

</style>
